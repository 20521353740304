.card {
  margin: 1rem auto;
  border-radius: 6px;
  padding: 1rem;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;

  font-size: 1.6rem;
}
