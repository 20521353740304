.searchLayout {
  max-width: 80%;
  margin: 0 auto;
  padding-top: 1rem;
}

.searchLayout p {
  display: inline-block;
}
.searchLayout svg {
  width: 2rem;
}
