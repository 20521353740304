.teacher {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  align-items: center;
  gap: 0.8rem;
}

.statistic {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}
