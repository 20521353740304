.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.loadingOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}
