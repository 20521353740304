.modal {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: #6200ea;
  color: white;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
}

.modal__footer {
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: center;
}

.modal-enter {
  transform: translate(-50%, -100%);
  opacity: 0;
}

.modal-enter-active {
  transform: translate(-50%, -50%);

  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translate(-50%, -50%);
  opacity: 1;
}

.modal-exit-active {
  transform: translate(-50%, -100%);
  opacity: 0;
  transition: all 200ms;
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
  }
}
